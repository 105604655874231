import React, { useState } from 'react';
import { IonItem, IonText, IonLabel, IonIcon, IonNote, IonButtons, IonButton } from '@ionic/react';
import './ServiceListItem.css';
import Moment from 'react-moment';
import 'moment/locale/it';
import { personCircleOutline, callOutline, closeCircleOutline, trashOutline, pencilOutline, personCircleSharp, pencilSharp } from 'ionicons/icons';
// import CurrencyFormat from 'react-currency-format';

interface ItemProps {
    service: Service,
    deleteAction: Function,
    selectionAction: Function
}


const ServiceListItem: React.FC<ItemProps> = ({ service, deleteAction, selectionAction }) => {

    return (

        <IonItem className="booking-item">
            <IonText>
                <h1>{service.name}</h1>
                <p>{(service.description) ? service.description : ''}</p>
                <p>Durata: {service.duration} min</p>
                <p>Costo: {(service.price) ? service.price + '€' : 'Non specificato'}</p>
            </IonText>
            <IonButton onClick={()=>selectionAction(service)} fill="clear" slot="end">
                <IonIcon slot="icon-only" icon={pencilSharp} />
            </IonButton>
            <IonButton onClick={()=>deleteAction(service)} color="danger" fill="clear" slot="end">
                <IonIcon slot="icon-only" icon={trashOutline} />
            </IonButton>
        </IonItem>
    );
};

export default ServiceListItem;
