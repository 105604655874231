import React, { useState } from 'react';
import { IonItem, IonText, IonLabel, IonIcon, IonNote, IonButtons, IonButton } from '@ionic/react';
import './DayListItem.css';
import Moment from 'react-moment';
import 'moment/locale/it';
import { personCircleOutline, callOutline, closeCircleOutline, pencilOutline } from 'ionicons/icons';
// import CurrencyFormat from 'react-currency-format';

interface ItemProps {
    booking: Booking,
    deleteAction: Function,
    selectionAction: Function
}


const DayListItem: React.FC<ItemProps> = ({ booking, deleteAction, selectionAction }) => {

    console.log('booking: ', booking);

    return (

        <IonItem onClick={() => selectionAction(booking)} className="booking-item">
            <IonLabel className="ion-text-wrap">
                <IonText>
                    <h2 className="booking-details"><strong>{booking.service.name} {(booking.service.type !== 'access') ? ' con ' + booking.operator.name : ''}</strong></h2>
                    <p className="booking-notes"><IonIcon icon={personCircleOutline}></IonIcon>  {(booking.custom_ref) ?? booking.user.firstname + ' ' + booking.user.lastname}</p>
                    <p className="booking-notes"><IonIcon icon={pencilOutline}></IonIcon>  {(booking.custom_note) ?? 'Nessuna nota'}</p>
                </IonText>
            </IonLabel>
            <IonNote color="light">
                <h1><Moment locale="it" format="HH:mm">{booking.date + " " + booking.start}</Moment></h1>

                {/* <IonButtons>
                    <IonButton size="default" color="danger" fill="solid" slot="start"><IonIcon icon={closeCircleOutline}></IonIcon></IonButton>
                    <IonButton size="default" color="primary" fill="solid" slot="end"><IonIcon icon={callOutline}></IonIcon></IonButton>
                </IonButtons> */}
            </IonNote>
        </IonItem>
    );
};

export default DayListItem;
