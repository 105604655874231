import React, { useState } from 'react';
import { IonList, IonListHeader } from '@ionic/react';
import 'moment/locale/it';
import Moment from 'react-moment';
import ServiceListItem from '../ServiceListItem/ServiceListItem';
import moment from 'moment';
// import CurrencyFormat from 'react-currency-format';

interface ItemProps {
    selectionAction: Function,
    services: Service[],
    deleteAction: Function
}

const ServiceList: React.FC<ItemProps> = ({ services, selectionAction, deleteAction }) => {


    return (
        <IonList>
            {services.map(service => (
                <ServiceListItem key={service.id} deleteAction={(service: Service) => deleteAction(service)} selectionAction={(service: Service) => selectionAction(service)} service={service}></ServiceListItem>
            ))}
        </IonList>
    );
};

export default ServiceList;
