import React, { useState } from 'react';
import { IonItem, IonText, IonLabel, IonIcon, IonNote, IonButtons, IonButton, IonToggle, IonAvatar, IonImg } from '@ionic/react';
import './OperatorListItem.css';
import Moment from 'react-moment';
import 'moment/locale/it';
import { personCircleOutline, callOutline, closeCircleOutline, trashOutline, pencilOutline, personCircleSharp, pencilSharp, time } from 'ionicons/icons';
// import CurrencyFormat from 'react-currency-format';

interface ItemProps {
    operator: Operator,
    toggleAction: Function,
    selectionAction: Function
}


const OperatorListItem: React.FC<ItemProps> = ({ operator, toggleAction, selectionAction }) => {

    return (

        <IonItem className="booking-item">
            <IonAvatar slot="start">
                <IonImg src={(operator.avatar) ? operator.avatar : '/assets/avatar.svg'} />
            </IonAvatar>
            <IonLabel>
                <h1>{operator.name}</h1>
                <p>{operator.firstname} {operator.lastname}</p>
                <p>{operator.email}</p>
                <IonButton routerLink={'/team/' + operator.id + '/hours'}  slot="end">
                    <IonIcon slot="start" icon={time} />
                    Orari
                </IonButton>
            </IonLabel>
            {/* <IonText>
                <h1>{operator.name}</h1>
            </IonText> */}

            <IonButton onClick={() => selectionAction(operator)} fill="clear" slot="end">
                <IonIcon slot="icon-only" icon={pencilSharp} />
            </IonButton>
            <IonToggle onClick={(e) => { toggleAction(operator); }} checked={operator.active} slot="end"></IonToggle>
        </IonItem>
    );
};

export default OperatorListItem;
