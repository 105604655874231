import request from '../request'
import { Plugins } from '@capacitor/core';
const { Modals } = Plugins;

export interface Credentials {
    email?: string | undefined | null,
    password?: string | undefined | null
};

export interface RegistrationFields {
    email?: string | undefined | null,
    password?: string | undefined | null,
    firstname?: string | undefined | null,
    lastname?: string | undefined | null
};

interface AuthResponse {
    user: User,
    access_token: string,
    refresh_token: string

}


function login(credentials: Credentials) {
    return request({
        url: `/admin/login`,
        method: 'POST',
        data: credentials
    }).catch(() => {
        showMessage('Errore', 'Impossibile accedere. Controlla le credenziali inserite.');
    });
}

function register(data: RegistrationFields) {
    return request({
        url: `/register`,
        method: 'POST',
        data: data
    }).catch(() => {
        showMessage('Errore', 'Impossibile registrarsi. Controlla i campi immessi.');
    });
}


function resetPassword(email: string) {
    return request({
        url: `/password-reset`,
        method: 'POST',
        data: { email: email }
    }).then(() => {
        showMessage('Richiesta inviata', 'Controlla la casella e-mail e segui le istruzioni per resettare la password.');
    }).catch(() => {
        showMessage('Errore', 'Impossibile resettare la password. Controlla i campi immessi.');
    });
}

function logout(tokens: any) {

    return request({
        url: `/logout`,
        method: 'POST'
    }, tokens);

}

const showMessage = async (title: string, message: string) => {
    let alert = await Modals.alert({
        title: title,
        message: message
    });
}


function refreshToken(token: string) {
    return request({
        url: `/refresh-token`,
        method: 'POST',
        data: { 'refresh_token': token }
    }).catch(() => {
        
    });
}



const AuthService = {
    login, register, resetPassword, logout, refreshToken
}

export default AuthService;