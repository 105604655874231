import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonActionSheet, IonFab, IonFabButton, IonIcon, IonButton, IonModal, IonItem, IonLabel, IonSelect, IonSelectOption, IonText, IonSegment, IonSegmentButton, IonInput, IonRow, IonGrid, IonCol, IonListHeader, IonCard, IonCardHeader, IonCardContent, IonNote, IonToggle, IonDatetime } from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import { trash, callOutline, closeCircleOutline, add, filterOutline, addCircleOutline, calendarOutline, refreshCircleOutline, saveOutline } from 'ionicons/icons';
import DayView from '../../components/Calendar/DayView/DayView';

import './Shops.css';

import { Plugins } from '@capacitor/core';
import NewBooking from '../../components/Bookings/NewBooking/NewBooking';
import OpeningsManager from '../../components/OpeningsManager/OpeningsManager';
import { AppContext } from '../../AppContextProvider';
import ShopService from '../../shared/lib/services/ShopService';
import ShopEditor from '../../components/ShopEditor/ShopEditor';
const { Modals } = Plugins;




const Shops: React.FC = () => {

  const globalState = useContext(AppContext);
  const { dispatch } = globalState;
  const { state } = globalState;


  const [currentShop, setCurrentShop] = useState<any>();
  const [currentShopId, setCurrentShopId] = useState<string>();

  const changeShop = (shop: string | undefined) => {
    setCurrentShopId(shop);
    setCurrentShop(JSON.parse(JSON.stringify(shops)).filter((obj: any) => { return String(obj.id) == shop; })[0]);
  }



  const loadShops = () => {

    ShopService.search(state.tokens).then((response) => {
      setShops(response.data);
      //if (currentShop == undefined) {
        setCurrentShopId(String(response.data[0].id));
        setCurrentShop(response.data[0]);
      //}
    });
  }

  const updateInfo = (updatedShop: any) => {
    //console.log(updatedShop.id,updatedShop);
    ShopService.update(state.tokens, updatedShop.id, updatedShop).then((data: any) => {
      Modals.alert({ title: "Confermato!", message: "Le modifiche sono state correttamente apportate.", buttonTitle: "OK" });
      loadShops();
    });
  }

  const [shops, setShops] = useState<Shop[]>([]);

  useEffect(() => {
    loadShops();
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Sedi</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {/* <IonFab vertical="top" horizontal="end">
          <IonFabButton onClick={() => {  }}>
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab> */}
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Sedi</IonTitle>
          </IonToolbar>

        </IonHeader>

        <IonToolbar>
          <IonSegment value={currentShopId} scrollable onIonChange={(e) => changeShop(e.detail.value)}>
            {shops.map(shop => (
              <IonSegmentButton key={shop.id} value={String(shop.id)}>
                {shop.name}
              </IonSegmentButton>))}
          </IonSegment>
        </IonToolbar>
        {(currentShop !== undefined) && (
          <IonGrid>
            <IonRow className="ion-justify-content-center">
              <IonCol size="12">
                <IonCard>
                  <IonCardHeader>
                    <h1>Dettagli</h1>
                    <p>Scegli il nome e l'indirizzo che gli utenti vedranno per questa sede</p>
                  </IonCardHeader>
                  <IonCardContent className="ion-no-padding">
                    <ShopEditor shop={currentShop} updateAction={(updatedShop: any) => { updateInfo(updatedShop) }}></ShopEditor>
                  </IonCardContent>
                </IonCard>

              </IonCol>
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage >
  );
};

export default Shops;
