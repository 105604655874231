import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonFab, IonFabButton, IonIcon, IonRow, IonCol, IonGrid, IonModal, IonButton } from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import { add } from 'ionicons/icons';

import './Team.css';

import { Plugins } from '@capacitor/core';
import OperatorList from '../../components/Operators/OperatorList/OperatorList';
import { AppContext } from '../../AppContextProvider';
import OperatorService from '../../shared/lib/services/OperatorService';
import ServiceEditor from '../../components/Services/ServiceEditor/ServiceEditor';
import OperatorEditor from '../../components/Operators/OperatorEditor/OperatorEditor';
const { Modals } = Plugins;


const Team: React.FC = () => {

  const globalState = useContext(AppContext);
  const { dispatch } = globalState;
  const { state } = globalState;


  const [showNewModal, setShowNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentOperator, setCurrentOperator] = useState<Operator>();
  const [operators, setOperators] = useState<Operator[]>([]);
  const [rerender, setRerender] = useState<number>(0);


  const loadOperators = () => {
    OperatorService.list(state.tokens).then((response: any) => {
      setOperators(response.data);
    });
  }

  const saveNewOperator = (operator: Operator) => {
    OperatorService.create(state.tokens, operator).then((response) => {
      console.log(response);
      setShowNewModal(false);
      loadOperators();
    }).catch((err) => {
      alert('Impossibile completare la richiesta!');
    })
  }

  const updateOperator = (operator: Operator) => {
    OperatorService.update(state.tokens, operator.id, operator).then((response) => {
      loadOperators();
      setShowEditModal(false);
      setCurrentOperator(undefined);
    }).catch((error) => {
      alert('Impossibile aggiornare!');
    });
  }

  const toggleOperator = async (operator: Operator) => {
    let status = !operator.active;
    let confirmRet = await Modals.confirm({
      title: 'Annulla',
      message: 'Sei sicuro di voler cambiare lo stato dell\'operatore?'
    });
    if (confirmRet.value == true) {
      OperatorService.updateStatus(state.tokens, operator.id, status).finally(() => {
        loadOperators();
      });
    } else {
      setRerender(rerender+1);
    }
  }

  useEffect(() => {
    loadOperators();
  }, []);

  useEffect(() => {

  }, [rerender]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Operatori</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonFab vertical="top" horizontal="end">
          <IonFabButton onClick={() => { setShowNewModal(true); }}>
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Operatori</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol>
              {(operators.length > 0 &&
                <OperatorList toggleAction={(operator: Operator) => toggleOperator(operator)} selectionAction={(operator: any) => { console.log('from selection: ', operator); setCurrentOperator(operator); setShowEditModal(true); }} operators={operators} />
              )}
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonModal cssClass="new-modal" swipeToClose={true} onDidDismiss={() => { setShowNewModal(false); }} isOpen={showNewModal}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => { setShowNewModal(false); }}>Annulla</IonButton>
              </IonButtons>
              <IonTitle>Nuovo operatore</IonTitle>
            </IonToolbar>
          </IonHeader>
          <OperatorEditor saveAction={(operator: any) => { saveNewOperator(operator); }} />
        </IonModal>

        <IonModal cssClass="new-modal" swipeToClose={true} onDidDismiss={() => { console.log(operators); setShowEditModal(false); }} isOpen={showEditModal}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => { setShowEditModal(false); }}>Annulla</IonButton>
              </IonButtons>
              <IonTitle>Modifica operatore</IonTitle>
            </IonToolbar>
          </IonHeader>
          <OperatorEditor operator={currentOperator} saveAction={(operator: any) => { updateOperator(operator); }} />
        </IonModal>
      </IonContent>
    </IonPage >
  );
};

export default Team;
