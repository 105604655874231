import React, { useState, useContext, useEffect, useRef } from 'react';
import { IonToolbar, IonSegment, IonSegmentButton, IonButtons, IonButton, IonIcon, IonFab, IonFabButton, IonText, IonModal, IonHeader, IonTitle, IonActionSheet, IonSelect, IonLabel, IonSelectOption, IonRow, IonGrid, IonCol } from '@ionic/react';
import 'moment/locale/it';
import DayList from './DayList/DayList';
import * as Moment from 'moment';
import { extendMoment, DateRange } from 'moment-range';
import { filterOutline, addCircleOutline, add, calendarOutline, alert, trash, callOutline, closeCircleOutline, filter } from 'ionicons/icons';
import './DayView.css';
import ChangeDates from './DayList/ChangeDates';
import BookingService from '../../../shared/lib/services/BookingService';
import { AppContext } from '../../../AppContextProvider';
import { Plugins } from '@capacitor/core';
import useInterval from '../../../shared/lib/hooks/useInterval';
import OperatorService from '../../../shared/lib/services/OperatorService';
import ShopService from '../../../shared/lib/services/ShopService';

const { Modals } = Plugins;

const moment = extendMoment(Moment);

// import CurrencyFormat from 'react-currency-format';

interface ItemProps {
    selectionAction: Function,
    newBookingAction: Function
}

const DayView: React.FC<ItemProps> = ({ selectionAction, newBookingAction }) => {

    const globalState = useContext(AppContext);
    const { dispatch } = globalState;
    const { state } = globalState;

    const getPeriod = (start: Moment.Moment | Date, end: Moment.Moment | Date) => {
        return moment.range(start, end);
    };

    const changeDate = (date: string) => {
        console.log(date);
        setCurrentDate(moment(date).format('YYYY-MM-DD'));
    }

    const cancel = (booking: Booking) => {
        BookingService.cancel(booking.id, state.tokens).then(() => {
            window.alert('Appuntamento annullato correttamente');
            loadBookings();
        })
    }

    const loadBookings = () => {
        BookingService.searchBookings(state.tokens, { start_date: currentDate, end_date: currentDate, page: -1, operator_id: currentOperator, shop_id: currentShop }).then((response) => {
            setBookings(response.data);
        });
    }
    const loadOperators = () => {
        OperatorService.list(state.tokens).then((response) => {
            setOperators(response.data);
        });
    }
    const loadShops = () => {
        ShopService.search(state.tokens).then((response) => {
            setShops(response.data);
        });
    }

    const showDeleteConfirm = async (booking: any) => {
        let confirmRet = await Modals.confirm({
            title: 'Annulla',
            message: 'Sei sicuro di voler annullare la prenotazione?'
        });
        console.log('Confirm ret', confirmRet);
        if (confirmRet.value == true) cancel(booking);
    }

    const showBookingAction = (booking: Booking) => {
        setSelectedBooking(booking);
        setShowActionSheet(true);
    }


    const [period, setPeriod] = useState<DateRange>(getPeriod(moment(), moment().add(1, 'month')));
    const [currentDate, setCurrentDate] = useState<any>(moment().format('YYYY-MM-DD'));
    const [bookings, setBookings] = useState<Booking[]>([]);
    const [operators, setOperators] = useState<Operator[]>([]);
    const [shops, setShops] = useState<Shop[]>([]);
    const [currentOperator, setCurrentOperator] = useState<string>();
    const [currentShop, setCurrentShop] = useState<string>();
    const [showDatesModal, setShowDatesModal] = useState<boolean>(false);
    const [showFiltersModal, setShowFiltersModal] = useState<boolean>(false);
    const [showActionSheet, setShowActionSheet] = useState(false);
    const [selectedBooking, setSelectedBooking] = useState<Booking>();


    useEffect(() => {
        loadBookings();
    }, [currentDate, currentOperator, currentShop]);

    useEffect(() => {
        loadOperators();
        loadShops();
    }, []);

    //custom useInterval hook
    useInterval(() => {
        loadBookings();
    }, 5000);


    return (
        <div>
            <IonToolbar className="ion-padding-top">

                {/* <IonButton onClick={() => setShowFiltersModal(true)} color="primary" fill="clear">
                        <IonIcon className="ion-padding" icon={filterOutline}></IonIcon>
                        <IonText className="ion-margin">Filtra</IonText>
                    </IonButton> */}
                <IonGrid>
                    <IonRow>
                        <IonCol size="12">
                            <IonButtons>
                                <IonButton onClick={() => setShowDatesModal(true)} color="primary" fill="clear">
                                    <IonIcon icon={calendarOutline}></IonIcon>
                                    <IonText className="ion-margin">Cambia date</IonText>
                                </IonButton>
                            </IonButtons>
                        </IonCol>
                        <IonCol size="6">
                            <IonLabel>
                                Filtra per operatore:
                                </IonLabel>
                            <IonSelect onIonChange={(e) => {
                                setCurrentOperator(e.detail.value);
                            }}>
                                <IonSelectOption key={0} value={null}>
                                    Tutti
                        </IonSelectOption>
                                {operators.map((operator) => (
                                    <IonSelectOption key={operator.id} value={operator.id}>
                                        {operator.name}
                                    </IonSelectOption>))}
                            </IonSelect>
                        </IonCol>
                        <IonCol size="6">
                            <IonLabel>
                                Filtra per sede:
                    </IonLabel>
                            <IonSelect onIonChange={(e) => {
                                setCurrentShop(e.detail.value);
                            }}>
                                <IonSelectOption key={0} value={null}>
                                    Tutte
                        </IonSelectOption>
                                {shops.map((shop) => (
                                    <IonSelectOption key={shop.id} value={shop.id}>
                                        {shop.name}
                                    </IonSelectOption>))}
                            </IonSelect>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonToolbar>

            <IonToolbar>
                <IonSegment value={currentDate} scrollable onIonChange={(e) => changeDate(String(e.detail.value))}>
                    {Array.from(period.by('day')).map((date: { format: (arg0: string) => any; }) => (
                        <IonSegmentButton key={date.format('YYYY-MM-DD')} value={date.format('YYYY-MM-DD')}>
                            {date.format("ddd D")}
                        </IonSegmentButton>))}
                </IonSegment>
            </IonToolbar>
            <IonFab vertical="top" horizontal="end" slot="fixed">
                <IonFabButton onClick={() => { newBookingAction() }}>
                    <IonIcon icon={add} />
                </IonFabButton>
            </IonFab>
            <DayList currentDate={currentDate} bookings={bookings} deleteAction={(booking: Booking) => { cancel(booking); }} selectionAction={(booking: Booking) => { showBookingAction(booking); }}></DayList>
            <IonModal cssClass="dates-modal" swipeToClose={true} onDidDismiss={() => { setShowDatesModal(false); }} isOpen={showDatesModal}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="end">
                            <IonButton onClick={() => { setShowDatesModal(false); }}>Annulla</IonButton>
                        </IonButtons>
                        <IonTitle>Cambia date</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <ChangeDates saveAction={(newPeriod: DateRange) => { setPeriod(newPeriod); setCurrentDate(newPeriod.start); setShowDatesModal(false); }} period={period}></ChangeDates>
            </IonModal>
            <IonActionSheet
                isOpen={showActionSheet}
                onDidDismiss={() => setShowActionSheet(false)}
                buttons={[{
                    text: 'Elimina',
                    role: 'destructive',
                    icon: trash,
                    handler: () => {
                        showDeleteConfirm(selectedBooking)
                    }
                }, {
                    text: 'Chiama utente',
                    icon: callOutline,
                    handler: () => {
                        if (selectedBooking?.user.phone) { window.open('tel:' + selectedBooking.user.phone, '_blank'); } else {
                            Modals.alert({
                                title: "Non disponibile",
                                message: 'L\'utente non ha ancora inserito un numero vaido'
                            });
                        }
                    }
                }, {
                    text: 'Annulla',
                    icon: closeCircleOutline,
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    }
                }]}
            >
            </IonActionSheet>
        </div>
    );
};

export default DayView;
