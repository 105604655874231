import request from '../request'

function search(tokens: any) {
    return request({
        url: `/admin/shop`,
        method: 'GET'
    }, tokens);
}

function get(id: string) {
    return request({
        url: `/admin/shop/${id}`,
        method: 'GET'
    });
}

function updateOpenings(tokens: any, id: any | number, openings: any) {
    return request({
        url: `/admin/shop/${id}/openings`,
        method: 'PUT',
        data: { openings: openings }
    }, tokens);
}

function update(tokens: any, id: string | number, data: any) {
    return request({
        url: `/admin/shop/${id}`,
        method: 'PUT',
        data:data
    }, tokens);
}

const ShopService = {
    search, get, updateOpenings, update //, update, delete, etc. ...
}

export default ShopService;