import request from '../request'
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;



function get(tokens: any) {
    return request({
        url: `/admin/user`,
        method: 'GET'
    }, tokens);
}




const UserService = {
    get
};

export default UserService;