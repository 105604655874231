import React, { useState, useEffect, useContext } from 'react';
import { IonButton, IonContent, IonItem, IonLabel, IonSelect, IonSelectOption, IonText } from '@ionic/react';
import { AppContext } from '../../../AppContextProvider';
import BookingCardFlow from '../BookingCardFlow/BookingCardFlow';

// import CurrencyFormat from 'react-currency-format';

interface ItemProps {
    onBookingComplete: Function
}




const NewBooking: React.FC<ItemProps> = ({onBookingComplete}) => {

    const globalState = useContext(AppContext);
    const { state } = globalState;
    const { dispatch } = globalState;


    // const shops: Shop[] = [
    //     {
    //         id: 1,
    //         name: "Sede principale",
    //         openings:[{
    //             id:1,
    //             day:1,
    //             open: "09:00",
    //             close: "18:00"
    //         }]
    //     }
    // ];
    // const services: Service[] = [

    // ];
    // const operators: Operator[] = [

    // ];
    // const dates: Array<string> = [

    // ];
    // const slots: Array<string> = [

    // ];

    // interface NewBooking {
    //     shop_id?: number,
    //     operator_id?: number,
    //     service_id?: number,
    //     date?: string,
    //     start?: string
    // };

    // const [newBooking, setNewBooking] = useState<NewBooking>({
    //     shop_id: undefined,
    //     operator_id: undefined,
    //     service_id: undefined,
    //     date: undefined,
    //     start: undefined
    // });

    // const [serviceEnabled, setServiceEnabled] = useState<boolean>(false);
    // const [operatorEnabled, setOperatorEnabled] = useState<boolean>(false);
    // const [dateEnabled, setDateEnabled] = useState<boolean>(false);
    // const [slotEnabled, setSlotEnabled] = useState<boolean>(false);
    // const [confirmEnabled, setConfirmEnabled] = useState<boolean>(false);




    return (
        <IonContent>
            <IonItem className="ion-padding">
                <IonText>
                    <h2>Prenota manualmente per un tuo cliente.</h2>
                </IonText>
            </IonItem>
            <BookingCardFlow onBookingComplete={onBookingComplete} business={state.user.app_owned}></BookingCardFlow>
            {/* <IonItem>
                <IonLabel>1. Sede</IonLabel>
                <IonSelect placeholder="Scegli una sede" onIonChange={e => { let newObj = newBooking; newObj.shop_id = e.detail.value; setNewBooking(newObj); setServiceEnabled(true); }}>
                    {shops.map(shop => (<IonSelectOption value={shop.id}>{shop.name}</IonSelectOption>))}
                </IonSelect>
            </IonItem>
            <IonItem disabled={!serviceEnabled}>
                <IonLabel>2. Servizio</IonLabel>
                <IonSelect placeholder="Scegli un servizio" onIonChange={e => { let newObj = newBooking; newObj.service_id = e.detail.value; setNewBooking(newObj); setOperatorEnabled(true); }}>
                    {services.map(service => (<IonSelectOption value={service.id}>{service.name}</IonSelectOption>))}
                </IonSelect>
            </IonItem>
            <IonItem disabled={!operatorEnabled}>
                <IonLabel>3. Operatore</IonLabel>
                <IonSelect placeholder="Scegli l'operatore" onIonChange={e => { let newObj = newBooking; newObj.operator_id = e.detail.value; setNewBooking(newObj); setDateEnabled(true); }}>
                    {operators.map(operator => (<IonSelectOption value={operator.id}>{operator.name}</IonSelectOption>))}
                </IonSelect>
            </IonItem>
            <IonItem disabled={!dateEnabled}>
                <IonLabel>4. Data</IonLabel>
                <IonSelect placeholder="Scegli la data" onIonChange={e => { let newObj = newBooking; newObj.date = e.detail.value; setNewBooking(newObj); setSlotEnabled(true); }}>
                    {dates.map(date => (<IonSelectOption value={date}>{date}</IonSelectOption>))}
                </IonSelect>
            </IonItem>
            <IonItem disabled={!slotEnabled}>
                <IonLabel>5. Orario</IonLabel>
                <IonSelect placeholder="Scegli l'orario" onIonChange={e => { let newObj = newBooking; newObj.start = e.detail.value; setNewBooking(newObj); setConfirmEnabled(true); }}>
                    {slots.map(slot => (<IonSelectOption value={slots}>{slot}</IonSelectOption>))}
                </IonSelect>
            </IonItem>
            <IonButton disabled={!confirmEnabled} className="ion-margin-top"  expand="block" fill="solid">Conferma</IonButton> */}
        </IonContent>
    );
};

export default NewBooking;
