import React from 'react';
import { IonItem, IonText, IonLabel, IonButton, IonIcon, IonNote } from '@ionic/react';
import './BookingItem.css';
import Moment from 'react-moment';
import 'moment/locale/it';
import { closeCircleOutline, personCircleOutline, book } from 'ionicons/icons';
// import CurrencyFormat from 'react-currency-format';

interface ItemProps {
    booking: Booking,
    deleteAction: Function
}


const BookingItem: React.FC<ItemProps> = ({ booking, deleteAction }) => {
    return (

        <IonItem>

            <IonLabel className="ion-text-wrap">
                <IonText>
                    <h1 className="booking-date"><Moment locale="it" format="DD/MM">{booking.date}</Moment></h1>
                    <h2 className="booking-details">{booking.service.name}  {(booking.service.type!=='access')?' con '+booking.operator.name:''}</h2>
                    <p className="booking-notes"><IonIcon icon={personCircleOutline}></IonIcon> {(booking.custom_ref)??booking.user.firstname+' '+booking.user.lastname}</p>
                </IonText>
            </IonLabel>
            <IonNote>
                <h1><Moment locale="it" format="HH:mm">{booking.date + " " + booking.start}</Moment></h1>
            </IonNote>
        </IonItem>
    );
};

export default BookingItem;
