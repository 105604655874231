import React, { useState, useContext, useEffect } from 'react';
import { IonGrid, IonRow, IonCol, IonAvatar, IonImg, IonLabel, IonItem, IonList, IonToolbar, IonButtons, IonButton, IonIcon, IonListHeader } from '@ionic/react';
import { call, logoWhatsapp, lockClosedSharp, lockOpen, lockClosed } from 'ionicons/icons';
import Moment from 'react-moment';

interface ItemProps {
    customer: User
}


const CustomerDetail: React.FC<ItemProps> = ({ customer }) => {

    return (
        <IonList>
            <IonItem className="ion-padding">
                <IonAvatar slot="start">
                    <IonImg src={(customer.avatar) ? customer.avatar : '/assets/avatar.svg'} />
                </IonAvatar>
                <IonLabel>
                    <h1>{customer.name}</h1>
                    <p>{customer.email}</p>
                </IonLabel>
            </IonItem>
            <IonListHeader>
                Informazioni
            </IonListHeader>
            <IonItem>
                <IonLabel>
                    <p><strong>Telefono:</strong> {customer.phone ?? 'Non disponibile'}</p>
                </IonLabel>
            </IonItem >
            <IonItem>
                <IonLabel>
                    <p><strong>Fonte:</strong> {customer.source ?? 'Non disponibile'}</p>
                </IonLabel>
            </IonItem >
            <IonListHeader>
                Statistiche
            </IonListHeader>
            <IonItem>
                <IonLabel>
                    <p><strong>Prima prenotazione:</strong> {<Moment format="DD/MM/YYYY" date={customer.app_first_booking?.date}></Moment> ?? 'Non disponibile'}</p>
                </IonLabel>
            </IonItem >
            <IonItem>
                <IonLabel>
                    <p><strong>Ultima prenotazione:</strong> {<Moment format="DD/MM/YYYY" date={customer.app_last_booking?.date}></Moment> ?? 'Non disponibile'}</p>
                </IonLabel>
            </IonItem >
            <IonItem>
                <IonLabel>
                    <p><strong>Prenotazioni totali:</strong> {customer.app_booking_count}</p>
                </IonLabel>
            </IonItem >
        </IonList>
    );

}

export default CustomerDetail;