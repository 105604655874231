import request from '../request'

function list(tokens: any) {
    return request({
        url: `/admin/service`,
        method: 'GET'
    }, tokens);
}

function get(id: string) {
    return request({
        url: `/admin/service/${id}`,
        method: 'GET'
    });
}


function create(tokens: any, data: any) {
    return request({
        url: `/admin/service`,
        method: 'POST',
        data: data
    }, tokens);
}


function update(tokens: any, id: string | number, data: any) {
    return request({
        url: `/admin/service/${id}`,
        method: 'PUT',
        data: data
    }, tokens);
}

function destroy(tokens: any, id: string | number) {
    return request({
        url: `/admin/service/${id}`,
        method: 'DELETE'
    }, tokens);
}


const ServiceService = {
    list, get, create, update, destroy
}

export default ServiceService;