import { Modals } from '@capacitor/core';
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonItem, IonIcon, IonLabel, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonRow, IonCol, IonGrid, IonText, IonListHeader, IonList, IonButton, IonInput } from '@ionic/react';
import { alert } from 'ionicons/icons';
import React, { useEffect, useContext, useState } from 'react';
import { AppContext } from '../../AppContextProvider';
import SendPushService from '../../shared/lib/services/SendPushService';

const Push: React.FC = () => {
    const globalState = useContext(AppContext);
    const { dispatch } = globalState;
    const { state } = globalState;

    const [push, setPush] = useState<any>({ title: '', message: '' });

    const send = () => {
        SendPushService.send(state.tokens, push.title, push.message).then((response) => {
            Modals.alert({ title: "Conferma", message: 'Push inviata correttamente!' });
            setPush({ title: '', message: '' });
        });
    }

    const updateField = (field: string, value: string | undefined | null) => {
        let p = push;
        p[field] = value;
        setPush(p);
    }

    useEffect(() => {
    }, [push]);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton />
                    </IonButtons>
                    <IonTitle color="primary">Nuova Push</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle color="primary" size="large">Nuova Push</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonItem>
                    <IonLabel position="floating">Titolo</IonLabel>
                    <IonInput onIonChange={(e) => { updateField('title', e.detail.value) }} value={push.title} type="text"></IonInput>
                </IonItem>
                <IonItem>
                    <IonLabel position="floating">Messaggio</IonLabel>
                    <IonInput onIonChange={(e) => { updateField('message', e.detail.value) }} value={push.message} type="text"></IonInput>
                </IonItem>
                <IonButton color="primary" onClick={send} fill="solid" expand="block">Invia notifica</IonButton>
            </IonContent>
        </IonPage >
    );
};

export default Push;
