import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonFab, IonFabButton, IonIcon, IonRow, IonCol, IonGrid, IonModal, IonButton, IonInfiniteScroll, IonInfiniteScrollContent, IonActionSheet, IonSearchbar, IonBackButton } from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import { callOutline, closeCircleOutline, lockClosed, lockOpen, personCircle, helpCircle, call, logoWhatsapp } from 'ionicons/icons';

import { Plugins } from '@capacitor/core';
import { AppContext } from '../../AppContextProvider';
import CustomerService from '../../shared/lib/services/CustomerService';
import CustomerList from '../../components/Customers/CustomerList/CustomerList';
import CustomerDetail from '../../components/Customers/CustomerDetail/CustomerDetail';
import GenericEmptyState from '../../components/EmptyStates/GenericEmptyState/GenericEmptyState';
import { RouteComponentProps } from 'react-router';

interface CustomerDetailPageProps extends RouteComponentProps<{
  id: string;
}> { }

const { Modals } = Plugins;


const Customer: React.FC<CustomerDetailPageProps> = ({ match }) => {

  let customer_id: any = match.params.id;

  const globalState = useContext(AppContext);
  const { state } = globalState;
  const [currentCustomer, setCurrentCustomer] = useState<User>();
  const [customerLoaded, setCustomerLoaded] = useState(false);

  const loadCustomer = () => {
    setCustomerLoaded(false);
    CustomerService.get(state.tokens, customer_id).then((response: any) => {
      console.log(response);
      setCurrentCustomer(response);
      setCustomerLoaded(true);
    });
  }

  const startCall = () => {
    if (currentCustomer?.phone) { window.open('tel:' + currentCustomer?.phone, '_blank'); } else {
      Modals.alert({
        title: "Non disponibile",
        message: 'L\'utente non ha ancora inserito un numero vaido'
      });
    }
  }

  const switchStatus = () => {
    switch (currentCustomer?.active_for_app) {
      case true:
        CustomerService.suspend(state.tokens, { user_id: currentCustomer.id }).then((response: any) => {
          loadCustomer();
        });

      case false:
        CustomerService.unsuspend(state.tokens, String(currentCustomer.id)).then((response: any) => {
          loadCustomer();
        });
    }


  }

  const openWhatsapp = () => {
    if (currentCustomer?.phone) { window.open('whatsapp://send?phone=' + currentCustomer?.phone, '_blank'); } else {
      Modals.alert({
        title: "Non disponibile",
        message: 'L\'utente non ha ancora inserito un numero vaido'
      });
    }
  }

  useEffect(() => {
    loadCustomer();
  }, []);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="Indietro" />
          </IonButtons>
          <IonTitle>Dettagli Cliente</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Dettagli Cliente</IonTitle>
          </IonToolbar>
        </IonHeader>

        {(currentCustomer &&
          <>
            <IonToolbar className="ion-padding-top ion-padding-bottom">
              <IonButtons slot="primary">
                <IonButton onClick={() => { switchStatus() }} fill="solid" className="ion-text-white" color={(currentCustomer.active_for_app) ? "danger" : "success"}>
                  {(currentCustomer.active_for_app) ? 'Blocca' : 'Sblocca'}
                  <IonIcon slot="end" icon={(currentCustomer.active_for_app) ? lockClosed : lockOpen} />
                </IonButton>
                <IonButton onClick={() => { startCall() }} fill="solid" color="primary">
                  Chiama
            <IonIcon slot="end" icon={call} />
                </IonButton>
                <IonButton onClick={() => { openWhatsapp() }} fill="solid" color="primary">
                  Whatsapp
            <IonIcon slot="end" icon={logoWhatsapp} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
            <CustomerDetail customer={currentCustomer}></CustomerDetail>
          </>)}
      </IonContent>
    </IonPage >
  );
};

export default Customer;
