import React, { useState, useEffect, useContext } from 'react';
import { IonItem, IonLabel, IonInput, IonButton, IonIcon, IonList, IonContent, IonSelect, IonSelectOption } from '@ionic/react';
import 'moment/locale/it';
import { saveOutline } from 'ionicons/icons';
import ServiceService from '../../../shared/lib/services/ServiceService';
import { AppContext } from '../../../AppContextProvider';
import ShopService from '../../../shared/lib/services/ShopService';
import OperatorService from '../../../shared/lib/services/OperatorService';
// import CurrencyFormat from 'react-currency-format';

interface ItemProps {
    operator?: Operator,
    saveAction: Function
}

const OperatorEditor: React.FC<ItemProps> = ({ operator, saveAction }) => {

    const globalState = useContext(AppContext);
    const { dispatch } = globalState;
    const { state } = globalState;

    const [currentOperator, setCurrentOperator] = useState<Operator | undefined>((operator)?JSON.parse(JSON.stringify(operator)):undefined);
    const [availableShops, setAvailableShops] = useState<Shop[]>([]);
    const [availableServices, setAvailableServices] = useState<Service[]>([]);


    const updateField = (field: string, value: string | undefined | null) => {
        console.log(field);
        let toUpdate: any = (currentOperator) ?? {};
        if (toUpdate !== undefined) toUpdate[field] = value;
        setCurrentOperator(toUpdate);
    }

    const loadServices = () => {
        ServiceService.list(state.tokens).then((response) => { setAvailableServices(response.data) });
    };

    const loadShops = () => {
        ShopService.search(state.tokens).then((response) => { setAvailableShops(response.data) });
    };

    const extractIds = (objects: any) => {
        let mapped = (objects && objects.length>0 && objects[0] !== undefined) ? JSON.parse(JSON.stringify(objects)).map((obj: any) => { return obj.id }) : [];
        return mapped;
    }

    useEffect(() => {
        loadServices();
        loadShops();
    }, []);

    return (
        <IonContent>
            <IonItem>
                <IonLabel position="floating">Nome mostrato in lista</IonLabel>
                <IonInput onIonChange={(e) => { updateField('name', e.detail.value) }} type="text" value={operator?.name}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="floating">Nome</IonLabel>
                <IonInput onIonChange={(e) => { updateField('firstname', e.detail.value) }} type="text" value={operator?.firstname}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="floating">Cognome</IonLabel>
                <IonInput onIonChange={(e) => { updateField('lastname', e.detail.value) }} type="text" value={operator?.lastname}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="floating">E-mail</IonLabel>
                <IonInput onIonChange={(e) => { updateField('email', e.detail.value) }} type="email" value={operator?.email}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>Sedi in cui è presente</IonLabel>
                <IonSelect multiple okText="OK" value={(operator)?JSON.parse(JSON.stringify(operator?.shops)).map((obj: any) => { return obj.id }):null} cancelText="Chiudi" onIonChange={e => updateField('shops', e.detail.value)}>
                    {availableShops.map(shop => (<IonSelectOption key={shop.id} value={shop.id}>{shop.name}</IonSelectOption>))}
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonLabel>Servizi che svolge</IonLabel>
                <IonSelect multiple okText="OK" value={(operator)?JSON.parse(JSON.stringify(operator?.services)).map((obj: any) => { return obj.id }):null} cancelText="Chiudi" onIonChange={e => updateField('services', e.detail.value)}>
                    {availableServices.map(service => (<IonSelectOption key={service.id} value={service.id}>{service.name}</IonSelectOption>))}
                </IonSelect>
            </IonItem>

            <IonButton color="primary" onClick={() => saveAction(currentOperator)} fill="solid" expand="block">Salva</IonButton>
        </IonContent>
    )
};

export default OperatorEditor;
