import React, { useState, useEffect, useContext } from 'react';
import { IonItem, IonLabel, IonInput, IonButton, IonIcon, IonList, IonContent, IonSelect, IonSelectOption, IonText } from '@ionic/react';
import 'moment/locale/it';
import { saveOutline } from 'ionicons/icons';
import { AppContext } from '../../../AppContextProvider';
import OperatorService from '../../../shared/lib/services/OperatorService';
// import CurrencyFormat from 'react-currency-format';

interface ItemProps {
    service?: Service,
    saveAction: Function
}

const ServiceEditor: React.FC<ItemProps> = ({ service, saveAction }) => {

    const globalState = useContext(AppContext);
    const { dispatch } = globalState;
    const { state } = globalState;

    const [currentService, setCurrentService] = useState<Service | undefined>(service);
    const [availableOperators, setAvailableOperators] = useState<Operator[]>([]);


    const updateField = (field: string, value: string | undefined | null) => {
        let toUpdate: any = (currentService) ?? {};
        if (toUpdate !== undefined) toUpdate[field] = value;
        setCurrentService(toUpdate);
    }

    const loadOperators = () => {
        OperatorService.list(state.tokens).then((response) => { setAvailableOperators(response.data) });
    };

    useEffect(() => {
        loadOperators();
    }, []);

    return (
        <IonContent>
            <IonItem>
                <IonLabel position="floating">Nome*</IonLabel>
                <IonInput onIonChange={(e) => { updateField('name', e.detail.value) }} type="text" value={service?.name}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="floating">Descrizione*</IonLabel>
                <IonInput onIonChange={(e) => { updateField('description', e.detail.value) }} type="text" value={service?.description}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="floating">Durata (minuti)*</IonLabel>
                <IonInput onIonChange={(e) => { updateField('duration', e.detail.value) }} type="number" value={service?.duration}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="floating">Prezzo (opzionale)</IonLabel>
                <IonInput onIonChange={(e) => { updateField('price', e.detail.value) }} type="number" value={service?.price}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="floating">Limite annullamento (in minuti)</IonLabel>
                <IonInput onIonChange={(e) => { updateField('cancel_limit', e.detail.value) }} type="number" value={service?.cancel_limit}></IonInput>
                <IonText color="dark" className="ionic-text-small"><p>Se impostato, gli utenti non potranno annullare se manca meno all'appuntamento</p></IonText>
            </IonItem>
            <IonItem>
                <IonLabel>Operatori che svolgono il servizio</IonLabel>
                <IonSelect multiple okText="OK" value={(service) ? JSON.parse(JSON.stringify(service?.operators)).map((obj: any) => { return obj.id }) : null} cancelText="Chiudi" onIonChange={e => updateField('operators', e.detail.value)}>
                    {availableOperators.map(operator => (<IonSelectOption key={operator.id} value={operator.id}>{operator.name}</IonSelectOption>))}
                </IonSelect>
            </IonItem>
            <IonButton color="primary" onClick={() => saveAction(currentService)} fill="solid" expand="block">Salva</IonButton>
        </IonContent>
    )
};

export default ServiceEditor;
