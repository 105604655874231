import { IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon, IonButton, IonModal, IonItem, IonLabel, IonSelect, IonSelectOption, IonGrid, IonRow, IonCol, IonSegment, IonSegmentButton, IonBackButton, IonText, IonListHeader, IonCard, IonCardSubtitle, IonCardHeader, IonCardContent, IonCardTitle, IonList, IonNote, IonFooter } from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import { add, pencilOutline, trashOutline } from 'ionicons/icons';
import { Plugins } from '@capacitor/core';
import IonTimeRange from '../../components/IonTimeRange/IonTimeRange';
import OperatorService from '../../shared/lib/services/OperatorService';
import ShopService from '../../shared/lib/services/ShopService';
import { AppContext } from '../../AppContextProvider';
import { RouteComponentProps } from 'react-router';
import OperatorHourService from '../../shared/lib/services/OperatorHourService';
import Moment from 'react-moment';

const { Modals } = Plugins;

interface OperatorHoursPageProps extends RouteComponentProps<{
    id: string;
}> { }

const OperatorHours: React.FC<OperatorHoursPageProps> = ({ match }) => {

    let operator_id: any = match.params.id;

    const globalState = useContext(AppContext);
    const { dispatch } = globalState;
    const { state } = globalState;

    const [showNewModal, setShowNewModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);

    const [shops, setShops] = useState<Shop[]>([]);
    const [operator, setOperator] = useState<Operator>();
    const [operatorHours, setOperatorHours] = useState<OperatorHour[]>();
    const [currentShop, setCurrentShop] = useState<number>();
    const [currentDay, setCurrentDay] = useState<string>('monday');
    const [currentInterval, setCurrentInterval] = useState<any>();
    const [updatedInterval, setUpdatedInterval] = useState<any>();
    const [newInterval, setNewInterval] = useState<OperatorHour>();

    const days = [
        { display: 'Lunedì', name: 'monday' },
        { display: 'Martedì', name: 'tuesday' },
        { display: 'Mercoledì', name: 'wednesday' },
        { display: 'Giovedì', name: 'thursday' },
        { display: 'Venerdì', name: 'friday' },
        { display: 'Sabato', name: 'saturday' },
        { display: 'Domenica', name: 'sunday' }
    ];

    const showNewIntervalModal = () => {
        if (currentShop) setNewInterval({ start: '09:00:00', finish: '18:00:00', operator_id: operator_id, day: currentDay, shop_id: currentShop });
        setShowNewModal(true);
    }

    const showUpdateIntervalModal = (interval: any) => {
        setCurrentInterval(interval);
        setShowUpdateModal(true);
    }

    const loadOperator = () => {
        OperatorService.get(state.tokens, operator_id).then((response) => {
            console.log(response);
            setOperator(response);
        });
    }

    const loadOperatorHours = () => {
        if (currentShop) OperatorService.getHours(state.tokens, operator_id, currentShop, currentDay).then((response) => {
            setOperatorHours(response);
        });
    }

    const loadShops = (setFirst = false) => {
        ShopService.search(state.tokens).then((response) => {
            setShops(response.data);
            if (setFirst) setCurrentShop(response.data[0].id);
        });
    }

    const addInterval = () => {
        console.log(newInterval);
        const hour = { start: (newInterval?.start) ?? '09:00:00', finish: (newInterval?.finish) ?? '18:00:00', operator_id: operator_id, day: currentDay, shop_id: currentShop };
        OperatorHourService.create(state.tokens, hour).then((res) => {
            loadOperatorHours();
            setShowNewModal(false);
        });
    };

    const updateInterval = () => {
        const hour = { start: updatedInterval.start, finish: updatedInterval.finish, operator_id: operator_id, day: currentDay, shop_id: currentShop };
        OperatorHourService.update(state.tokens, currentInterval.id, hour).then((res) => {
            loadOperatorHours();
            setShowUpdateModal(false);
            setUpdatedInterval(null);
        });
    };

    const deleteInterval = (id: any) => {
        OperatorHourService.destroy(state.tokens, id).then((res) => {
            loadOperatorHours();
        });
    };

    useEffect(() => {
        loadOperatorHours();
    }, [currentDay, currentShop]);

    useEffect(() => {
        loadOperator();
        loadShops(true);
    }, []);

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonBackButton text="Indietro" />
                    </IonButtons>
                    <IonTitle>Orari {operator?.name}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Orari {operator?.name}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {/* <IonTitle>
                    {operator?.name}
                </IonTitle> */}
                <div className="ion-padding">
                    <p>Per ciascuna sede, l'operatore sarà prenotabile solo nei giorni e negli orari indicati.</p>
                </div>
                <IonItem className="ion-margin-bottom">
                    <IonLabel>
                        <strong>
                            Scegli sede:
                            </strong>
                    </IonLabel>
                    <IonSelect value={currentShop} onIonChange={(e) => { setCurrentShop(e.detail.value); }}>
                        {shops.map((shop) => (
                            <IonSelectOption key={shop.id} value={shop.id}>
                                {shop.name}
                            </IonSelectOption>))}
                    </IonSelect>
                </IonItem>
                <IonSegment scrollable={true} onIonChange={(e) => { setCurrentDay(e.detail.value as any) }} value={currentDay}>
                    {days.map((day) => (
                        <IonSegmentButton key={day.name} value={day.name}>
                            {day.display.substring(0, 3).toUpperCase()}
                        </IonSegmentButton>))}
                </IonSegment>
                <IonList className="ion-padding">
                    {operatorHours?.map((interval) => (
                        <IonItem key={interval.id}>
                            <IonLabel>
                                <h2>Dalle <strong><Moment parse="HH:mm:ss" format="HH:mm">{interval.start}</Moment></strong> alle <strong><Moment parse="HH:mm:ss" format="HH:mm">{interval.finish}</Moment></strong></h2>
                            </IonLabel>
                            <IonButton onClick={() => showUpdateIntervalModal(interval)} slot="end">
                                <IonIcon slot="icon-only" icon={pencilOutline} />
                            </IonButton>
                            <IonButton onClick={() => deleteInterval(interval.id)} color="danger" slot="end">
                                <IonIcon slot="icon-only" icon={trashOutline} />
                            </IonButton>
                        </IonItem>))}

                    <div className="ion-text-center ion-padding">
                        <IonButton onClick={() => showNewIntervalModal()} className="ion-margin-top"><IonIcon slot="start" icon={add} />Aggiungi intervallo</IonButton>
                    </div>
                </IonList>
                {/* <DayView selectionAction={(booking: Booking) => { }} newBookingAction={showNewBookingModal} ></DayView> */}
                <IonModal cssClass="new-modal" swipeToClose={true} onDidDismiss={() => { setShowNewModal(false); }} isOpen={showNewModal}>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="end">
                                <IonButton onClick={() => { setShowNewModal(false); }}>Annulla</IonButton>
                            </IonButtons>
                            <IonTitle>Nuovo intervallo</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <div className="ion-margin-top ion-text-center">
                            <IonTimeRange changeHandler={(e: any) => { setNewInterval(e); }} showLabel={true}></IonTimeRange>
                        </div>
                        <IonButton onClick={() => addInterval()} expand="block" color="success">Salva</IonButton>
                    </IonContent>
                </IonModal>
                <IonModal cssClass="new-modal" swipeToClose={true} onDidDismiss={() => { setShowUpdateModal(false); setCurrentInterval(null); }} isOpen={showUpdateModal}>
                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot="end">
                                <IonButton onClick={() => { setShowUpdateModal(false); setCurrentInterval(null); }}>Annulla</IonButton>
                            </IonButtons>
                            <IonTitle>Modifica intervallo</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <div className="ion-margin-top ion-text-center">
                            <IonTimeRange start={(currentInterval) ? currentInterval.start : null} end={(currentInterval) ? currentInterval.finish : null} changeHandler={(e: any) => { setUpdatedInterval(e); }} showLabel={true}></IonTimeRange>
                        </div>
                        <IonButton onClick={() => updateInterval()} expand="block" color="success">Aggiorna</IonButton>
                    </IonContent>
                </IonModal>
            </IonContent>
        </IonPage>
    );
};

export default OperatorHours;
