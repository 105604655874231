import React, { useState, useEffect } from 'react';
import 'moment/locale/it';
import { IonItem, IonNote, IonToggle, IonGrid, IonRow, IonCol, IonLabel, IonDatetime, IonButton, IonIcon, IonList, IonInput } from '@ionic/react';
import { saveOutline } from 'ionicons/icons';

interface ItemProps {
    shop: Shop,
    updateAction: Function
}

const ShopEditor: React.FC<ItemProps> = ({ shop, updateAction }) => {



    const [newShop, setNewShop] = useState<Shop>(shop);

    useEffect(() => {
        setNewShop(JSON.parse(JSON.stringify(shop)));
    }, [shop]);

    const updateField = (field: string, value: string | undefined | null) => {
        let toUpdate: any = newShop;
        if (toUpdate !== undefined) toUpdate[field] = value;
        setNewShop(toUpdate);
    }

    return (
        <>
            <IonItem>
                <IonLabel position="floating">Nome</IonLabel>
                <IonInput onIonChange={(e) => { updateField('name', e.detail.value) }} value={shop?.name}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="floating">Indirizzo</IonLabel>
                <IonInput onIonChange={(e) => { updateField('address', e.detail.value) }} value={shop?.address}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="floating">Città</IonLabel>
                <IonInput onIonChange={(e) => { updateField('city', e.detail.value) }} value={shop?.city}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="floating">Provincia</IonLabel>
                <IonInput onIonChange={(e) => { updateField('province', e.detail.value) }} value={shop?.province}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel position="floating">CAP</IonLabel>
                <IonInput onIonChange={(e) => { updateField('zipcode', e.detail.value) }} value={shop?.zipcode}></IonInput>
            </IonItem>
            <IonButton onClick={() => { console.log(newShop); updateAction(newShop); }} fill="clear"><IonIcon icon={saveOutline}></IonIcon> Salva</IonButton>
        </>);
}
export default ShopEditor;