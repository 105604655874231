import request from '../request'


function get(tokens:any) {
    return request({
        url: `/admin/dashboard`,
        method: 'GET'
    }, tokens);
}


const DashboardService = {
     get //, update, delete, etc. ...
}

export default DashboardService;