import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonActionSheet, IonFab, IonFabButton, IonIcon, IonButton, IonModal, IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import React, { useState } from 'react';
import { trash, callOutline, closeCircleOutline, add, filterOutline, addCircleOutline, book } from 'ionicons/icons';
import DayView from '../../components/Calendar/DayView/DayView';

import './Calendar.css';

import NewBooking from '../../components/Bookings/NewBooking/NewBooking';
import { Plugins } from '@capacitor/core';

const { Modals } = Plugins;




const Calendar: React.FC = () => {



  const showNewBookingModal = () => {
    setNewBooking({});
    setShowNewModal(true);
  }

  const showConfirmation = async () => {
    await Modals.alert({
      title: 'Prenotazione Effettuata',
      message: 'La prenotazione è stata correttamente effettuata!',
      buttonTitle: 'OK'
    });

  }


  const [showNewModal, setShowNewModal] = useState(false);
  const [newBooking, setNewBooking] = useState<object>({});


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Calendario</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Calendario</IonTitle>
          </IonToolbar>
        </IonHeader>
        <DayView selectionAction={(booking: Booking) => { }} newBookingAction={showNewBookingModal} ></DayView>
        <IonModal cssClass="new-modal" swipeToClose={true} onDidDismiss={() => { setShowNewModal(false); setNewBooking({}); }} isOpen={showNewModal}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => { setShowNewModal(false); setNewBooking({}); }}>Annulla</IonButton>
              </IonButtons>
              <IonTitle>Nuova prenotazione</IonTitle>
            </IonToolbar>
          </IonHeader>
          <NewBooking onBookingComplete={() => { setShowNewModal(false); setNewBooking({}); showConfirmation();}}></NewBooking>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Calendar;
