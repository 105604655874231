import React, { useState, useEffect, useContext } from 'react';
import { IonCard, IonCardContent, IonSlides, IonSlide, IonListHeader, IonInput, IonItem, IonText, IonButton } from '@ionic/react';
import './BookingCardFlow.css'
import ShopsList from './Shops/ShopsList';

import BookingService from '../../../shared/lib/services/BookingService';
import ServicesList from './Services/ServicesList';
import OperatorsList from './Operators/OperatorsList';
import DatesList from './Dates/DatesList';
import SlotsList from './Slots/SlotsList';

import { Plugins } from '@capacitor/core';
import moment from 'moment';
import { AppContext } from '../../../AppContextProvider';
const { Modals } = Plugins;

interface ItemProps {
    business: Business,
    onBookingComplete: Function
}

const slideOpts = {
    slidesPerView: 1
};

const BookingCardFlow: React.FC<ItemProps> = ({ business, onBookingComplete }) => {

    const slides = document.querySelector('ion-slides');

    useEffect(() => {
        //loadShops(business.id);
        slides?.lockSwipes(true);
    }, []);

    const [currentBooking, setCurrentBooking] = useState<NewBooking>();

    //let shops:Shop[] = [];
    const globalState = useContext(AppContext);
    const { dispatch } = globalState;
    const { state } = globalState;


    const [customRef, setCustomRef] = useState<string | null>();
    const [customNote, setCustomNote] = useState<any>();
    const [shops, setShops] = useState<Shop[]>([]);
    const [services, setServices] = useState<Service[]>([]);
    const [operators, setOperators] = useState<Operator[]>([]);
    const [dates, setDates] = useState<string[]>([]);
    const [slots, setSlots] = useState<string[]>([]);

    const loadShops = (app_id: number) => {
        BookingService.getShops(String(app_id)).then((data) => {
            setShops(data.shops);
            //shops = data.shops;
        });
    }
    const loadServices = (app_id: number, shop_id: number) => {
        BookingService.getServices(String(app_id), shop_id).then((data) => {
            setServices(data.services);
        });
    }
    const loadOperators = (app_id: number, shop_id: number, service_id: number) => {
        BookingService.getOperators(String(app_id), String(shop_id), String(service_id)).then((data) => {
            setOperators(data.operators);
        });
    }
    const loadDates = (app_id: number, shop_id: number) => {
        BookingService.getDates(String(app_id), String(shop_id)).then((data) => {
            setDates(data.dates);
        });
    }
    const loadSlots = (app_id: number, shop_id: number, service_id: number, operator_id: number, date: string) => {
        BookingService.getSlots(String(app_id), String(shop_id), String(service_id), String(operator_id), date).then((data) => {
            setSlots(data.slots);
        });
    }

    const toShops = () => {

        if (customRef !== null) {

            setCurrentBooking({ app_id: business.id, custom_ref: customRef, custom_note: customNote });

            loadShops(business.id);

            slides?.lockSwipeToNext(false);
            slides?.slideTo(1);
            slides?.lockSwipeToNext(true);
        } else {
            alert('Devi inserire un nome valido.');
        }
    }

    const toServices = (shop: Shop) => {

        let updatedBooking: any = currentBooking;
        updatedBooking.shop_id = shop.id;
        setCurrentBooking(updatedBooking);

        loadServices(business.id, shop.id);

        slides?.lockSwipeToNext(false);
        slides?.slideTo(2);
        slides?.lockSwipeToNext(true);
    }
    const toOperators = (service: Service) => {
        let updatedBooking: any = currentBooking;
        updatedBooking.service_id = service.id;
        setCurrentBooking(updatedBooking);

        loadOperators(updatedBooking.app_id, updatedBooking.shop_id, updatedBooking.service_id);

        slides?.lockSwipeToNext(false);
        slides?.slideTo(3);
        slides?.lockSwipeToNext(true);
    }
    const toDates = (operator: Operator) => {
        let updatedBooking: any = currentBooking;
        updatedBooking.operator_id = operator.id;
        setCurrentBooking(updatedBooking);

        loadDates(updatedBooking.app_id, updatedBooking.shop_id);

        slides?.lockSwipeToNext(false);
        slides?.slideTo(4);
        slides?.lockSwipeToNext(true);
    }
    const toSlots = (date: string) => {
        let updatedBooking: any = currentBooking;
        updatedBooking.date = date;
        setCurrentBooking(updatedBooking);

        loadSlots(updatedBooking.app_id, updatedBooking.shop_id, updatedBooking.service_id, updatedBooking.operator_id, date);

        slides?.lockSwipeToNext(false);
        slides?.slideTo(5);
        slides?.lockSwipeToNext(true);
    }

    const showConfirm = async (slot: string) => {
        let confirmRet = await Modals.confirm({
            title: 'Conferma prenotazione',
            message: 'Sei sicuro di voler prenotare?'
        });
        switch (confirmRet.value) {
            case true:
                createBooking(moment(slot).format('HH:mm'));
                break;
            default:
                break;
        }
    }

    const createBooking = (slot: string) => {
        let updatedBooking: any = currentBooking;
        updatedBooking.start = slot;
        setCurrentBooking(updatedBooking);

        if (currentBooking) BookingService.create(currentBooking, state.tokens).then((response) => {
            slides?.lockSwipeToNext(false);
            slides?.slideTo(0);
            slides?.lockSwipeToNext(true);
            setCurrentBooking(undefined);
            onBookingComplete(response);
        }).catch(() => { });
    }

    return (

        <IonSlides options={slideOpts}>
            <IonSlide>
                <IonCard>
                    <IonCardContent>
                        <IonListHeader>Nome del cliente</IonListHeader>
                        <IonItem>
                            <IonInput placeholder="Nome" onIonChange={(e) => { setCustomRef(e.detail.value); }} type="text"></IonInput>
                        </IonItem>
                        <IonListHeader>Note (es. telefono)</IonListHeader>
                        <IonItem>
                            <IonInput placeholder="Note" onIonChange={(e) => { setCustomNote(e.detail.value); }} type="text"></IonInput>
                        </IonItem>
                        <IonButton disabled={!customRef} className="ion-margin-top" expand="block" onClick={toShops}>Procedi</IonButton>
                    </IonCardContent>
                </IonCard>
            </IonSlide>
            <IonSlide>
                <IonCard>
                    <IonCardContent className="booking-card-content">
                        <ShopsList key="shopslist" onSelection={(shop: Shop) => { toServices(shop); }} shops={shops}></ShopsList>
                    </IonCardContent>
                </IonCard>
            </IonSlide>
            <IonSlide>
                <IonCard>
                    <IonCardContent className="booking-card-content">
                        <ServicesList onSelection={(service: Service) => { toOperators(service); }} services={services}></ServicesList>
                    </IonCardContent>
                </IonCard>
            </IonSlide>
            <IonSlide>
                <IonCard>
                    <IonCardContent className="booking-card-content">
                        <OperatorsList onSelection={(operator: Operator) => { toDates(operator); }} operators={operators}></OperatorsList>
                    </IonCardContent>
                </IonCard>
            </IonSlide>
            <IonSlide>
                <IonCard>
                    <IonCardContent className="booking-card-content">
                        <DatesList onSelection={(date: string) => { toSlots(date); }} dates={dates}></DatesList>
                    </IonCardContent>
                </IonCard>
            </IonSlide>
            <IonSlide>
                <IonCard>
                    <IonCardContent className="booking-card-content">
                        <SlotsList slots={slots} onSelection={(slot: string) => { showConfirm(slot); }}></SlotsList>
                    </IonCardContent>
                </IonCard>
            </IonSlide>
        </IonSlides>
    );
};

export default BookingCardFlow;
