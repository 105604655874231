import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonButton,
  IonText,
  IonFooter,
  IonNote,
  IonButtons,
} from '@ionic/react';

import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { rocketOutline, calendarOutline, rocketSharp, calendarSharp, peopleOutline, peopleSharp, statsChartOutline, statsChartSharp, settingsOutline, settingsSharp, peopleCircleSharp, peopleCircleOutline, businessOutline, businessSharp, duplicateOutline, duplicateSharp, logOutOutline, eyeOutline, shareOutline, timeOutline, timeSharp, notificationsOutline, notificationsSharp, text, textSharp } from 'ionicons/icons';
import './Menu.css';
import AuthService from '../shared/lib/services/AuthService';
import { AppContext } from '../AppContextProvider';
import { Plugins } from '@capacitor/core';
const { Share } = Plugins;


interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
}

// const labels = ['Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'];
const { Storage } = Plugins;

const Menu: React.FC = () => {

  const globalState = useContext(AppContext);
  let { dispatch } = globalState;
  let { state } = globalState;

  let appPages: AppPage[] = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      iosIcon: rocketOutline,
      mdIcon: rocketSharp
    },
    {
      title: 'Calendario',
      url: '/calendar',
      iosIcon: calendarOutline,
      mdIcon: calendarSharp
    },
    {
      title: 'Sedi',
      url: '/shops',
      iosIcon: businessOutline,
      mdIcon: businessSharp
    },
    {
      title: 'Orari',
      url: '/openings',
      iosIcon: timeOutline,
      mdIcon: timeSharp
    },
    {
      title: 'Servizi',
      url: '/services',
      iosIcon: duplicateOutline,
      mdIcon: duplicateSharp
    },
    {
      title: 'Operatori',
      url: '/team',
      iosIcon: peopleCircleOutline,
      mdIcon: peopleCircleSharp
    },
    {
      title: 'Clienti',
      url: '/customers',
      iosIcon: peopleOutline,
      mdIcon: peopleSharp
    }
    // {
    //   title: 'Statistiche',
    //   url: '/stats',
    //   iosIcon: statsChartOutline,
    //   mdIcon: statsChartSharp
    // },
    // {
    //   title: 'Impostazioni',
    //   url: '/settings',
    //   iosIcon: settingsOutline,
    //   mdIcon: settingsSharp
    // }
  ];

  const logout = () => {
    clearAuth();
    dispatch({ type: 'REMOVE_AUTH', payload: {} });
    AuthService.logout(state.tokens);
  }

  const clearAuth = async () => {
    await Storage.remove({
      key: 'tokens'
    });
    await Storage.remove({
      key: 'user',
    });
  }

  if (state.user.app_owned.push_enabled) {
    appPages.push({
      title: 'Notifiche',
      url: '/push',
      iosIcon: notificationsOutline,
      mdIcon: notificationsSharp
    });
  }

  const share = async () => {
    let shareRet = await Share.share({
      title: 'Condividi link con i clienti',
      text: 'Prenota subito da ' + state.user.app_owned.name,
      url: 'https://app.agendafacile.com/business/' + state.user.app_owned.subdomain,
      dialogTitle: 'Condividi con i clienti'
    });
  }

  const location = useLocation();

  return (
    <IonMenu contentId="main" type="overlay">
      <IonContent>
        <IonList id="inbox-list">
          <IonListHeader className="ion-margin-bottom">{(state.user.app_owned.has_custom_app) ? state.user.app_owned.name : 'AgendaFacile'}</IonListHeader>
          {(!state.user.app_owned.has_custom_app && <IonItem onClick={share} lines="none" detail={false}>
            <IonIcon slot="start" icon={shareOutline} />
            <IonLabel>Condividi link con i clienti</IonLabel>
          </IonItem>)}
          {/* <IonItem>
            <IonText><h4>{state.user.app_owned.name}</h4>
              <IonNote className="ion-no-padding">{state.user.app_owned.subdomain}</IonNote>
              <IonButtons><IonButton href={'https://app.agendafacile.com/business/' + state.user.app_owned.subdomain} target="blank" fill="clear">Anteprima</IonButton> <IonButton onClick={()=>share()} fill="clear">Condividi</IonButton></IonButtons>
            </IonText>
          </IonItem> */}
          {/* <IonListHeader className="ion-margin-top ion-margin-bottom">Gestione</IonListHeader> */}
          {appPages.map((appPage, index) => {
            return (
              <IonMenuToggle key={index} autoHide={false}>
                <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                  <IonIcon slot="start" icon={appPage.iosIcon} />
                  <IonLabel>{appPage.title}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            );
          })}
          <IonItem onClick={() => { logout(); }} lines="none">
            <IonButton color="danger" fill="clear">
              <IonIcon color="danger" slot="start" icon={logOutOutline} />
              <IonLabel >Effettua il log-out</IonLabel>
            </IonButton>
          </IonItem>
        </IonList>
      </IonContent>
      <IonFooter>
        <IonText className="copyright-text ion-margin-top ion-padding-top">
          <p>© 2021 - AgendaFacile.com </p>
        </IonText>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;
