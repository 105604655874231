import request from '../request'

// function list(tokens: any) {
//     return request({
//         url: `/admin/operator`,
//         method: 'GET'
//     }, tokens);
// }

// function get(tokens: any, id: string) {
//     return request({
//         url: `/admin/operator/${id}`,
//         method: 'GET'
//     });
// }

// function getHours(tokens: any, id: number, shop_id: number, day: string) {
//     return request({
//         url: `/admin/operator/${id}/hours`,
//         method: 'GET',
//         params: { shop_id: shop_id, day: day }
//     }, tokens);
// }

function create(tokens: any, data: any) {
    return request({
        url: `/admin/operator-hour`,
        method: 'POST',
        data: data
    }, tokens);
}

function update(tokens: any, id: string | number, data: any) {
    return request({
        url: `/admin/operator-hour/${id}`,
        method: 'PUT',
        data: data
    }, tokens);
}


function destroy(tokens: any, id: string | number) {
    return request({
        url: `/admin/operator-hour/${id}`,
        method: 'DELETE'
    }, tokens);
}


const OperatorHourService = {
   create, update, destroy
}

export default OperatorHourService;