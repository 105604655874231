import React from 'react';
import { IonText, IonButton } from '@ionic/react';
import './GenericEmptyState.css';

interface ItemProps {
    item_type: string,
    cta_action?: Function,
    cta_title?: string,
    align?: string,
    custom_text?: string
}

const GenericEmptyState: React.FC<ItemProps> = ({ item_type, cta_action, cta_title, align, custom_text }) => {



    return (
        <div className={"ion-margin ion-text-" + (align) ?? 'center' + ' empty-state'}>
            <IonText className={"ion-text-" + (align) ?? 'center'}><strong>{(custom_text) ?? 'Non hai ancora ' + item_type}</strong></IonText>
            {((cta_action && cta_title) && <IonButton onClick={() => cta_action} >{cta_title}</IonButton>)}
        </div>
    );
};

export default GenericEmptyState;
