import React, { useState } from 'react';
import { IonList, IonListHeader, IonText, IonItem } from '@ionic/react';
import 'moment/locale/it';
import Moment from 'react-moment';
import DayListItem from '../DayListItem/DayListItem';
import moment from 'moment';
import BookingService from '../../../../shared/lib/services/BookingService';
import GenericEmptyState from '../../../EmptyStates/GenericEmptyState/GenericEmptyState';
// import CurrencyFormat from 'react-currency-format';

interface ItemProps {
    currentDate: any,
    selectionAction: Function,
    bookings: Booking[],
    deleteAction: Function

}

const DayList: React.FC<ItemProps> = ({ bookings, currentDate, selectionAction, deleteAction }) => {



    return (
        <>
            <IonList>
                <IonListHeader>
                    <Moment format="dddd DD MMMM" locale="it">{currentDate}</Moment>
                </IonListHeader>
                {bookings.map(booking => (
                    <DayListItem key={booking.id} selectionAction={selectionAction} deleteAction={(booking: Booking) => { deleteAction(booking) }} booking={booking}></DayListItem>
                ))}
            </IonList>
            {(bookings.length == 0 && <GenericEmptyState item_type="prenotazioni"></GenericEmptyState>)}
        </>
    );
};

export default DayList;
