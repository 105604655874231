import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonCard, IonItem, IonIcon, IonLabel, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonRow, IonCol, IonGrid, IonText, IonListHeader, IonList, IonButton } from '@ionic/react';
import React, { useEffect, useContext, useState } from 'react';
import { useParams } from 'react-router';
import './Dashboard.css';
import BookingItem from '../../components/Bookings/BookingItem/BookingItem';
import Moment from 'react-moment';
import moment from 'moment';
import DashboardService from '../../shared/lib/services/DashboardService';
import { AppContext } from '../../AppContextProvider';
import GenericEmptyState from '../../components/EmptyStates/GenericEmptyState/GenericEmptyState';

const Dashboard: React.FC = () => {
  const globalState = useContext(AppContext);
  const { dispatch } = globalState;
  const { state } = globalState;


  const [data, setData] = useState<any>();

  const loadData = () => {
    DashboardService.get(state.tokens).then((response) => {
      setData(response);
      console.log(response);
    });
  }


  useEffect(() => {
    loadData();
    //refresh data every 30 seconds
    setInterval(loadData, 30000);
  }, []);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle color="primary">Dashboard</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle color="primary" size="large">Dashboard</IonTitle>
          </IonToolbar>
        </IonHeader>
        {
          (data) &&
          <IonGrid>
            <IonRow className="ion-align-items-center">

              <IonCol size="12" sizeMd="6" className="ion-padding">
                <IonCardSubtitle><Moment locale="it" format="dddd D MMMM"></Moment></IonCardSubtitle>
              </IonCol>
            </IonRow>
            <IonRow className="ion-align-items-center">
              <IonCol size="6" sizeMd="3">
                <IonCard>
                  <IonCardHeader>
                    <IonCardSubtitle>Settimana</IonCardSubtitle>
                    <IonCardTitle>{data.weekly_booking_count}</IonCardTitle>
                  </IonCardHeader>

                  <IonCardContent>
                    Appuntamenti
                  {/* <IonText color="success">
                    <h5>{stats.week_bookings.delta}%</h5>
                  </IonText> */}
                  </IonCardContent>
                </IonCard>
              </IonCol>
              <IonCol size="6" sizeMd="3">
                <IonCard>
                  <IonCardHeader>
                    <IonCardSubtitle>Totali</IonCardSubtitle>
                    <IonCardTitle>{data.users_count}</IonCardTitle>
                  </IonCardHeader>
                  <IonCardContent>
                    Clienti
                  <IonText>
                      {/* <h5>Registrati</h5> */}
                    </IonText>
                  </IonCardContent>
                </IonCard>
              </IonCol>
            </IonRow>
            <IonList>
              <IonListHeader>
                <IonText color="primary" >Prossime prenotazioni</IonText>
              </IonListHeader>
              {
                (data.next_bookings == 0 ? (<GenericEmptyState align="left" item_type="prenotazioni"></GenericEmptyState>) :
                  (data.next_bookings.map((booking: Booking) => (
                    <BookingItem key={booking.id} booking={booking} deleteAction={() => { }}></BookingItem>
                  ))))
              }
            </IonList>
            {(data.next_bookings.length > 0 && <IonButton routerLink="/calendar" fill="clear">Vedi tutte</IonButton>)}
          </IonGrid>}

      </IonContent>
    </IonPage >
  );
};

export default Dashboard;
