import request from '../request'

function list(tokens: any, params: any = {}) {
    return request({
        url: `/admin/customer`,
        method: 'GET',
        params: params
    }, tokens);
}

function get(tokens: any, id: string) {
    return request({
        url: `/admin/customer/${id}`,
        method: 'GET'
    });
}

function suspend(tokens: any, data: any) {
    return request({
        url: `/admin/suspended-user`,
        method: 'POST',
        data: data
    }, tokens);
}

function unsuspend(tokens: any, id: string) {
    return request({
        url: `/admin/customer/${id}/unsuspend`,
        method: 'PUT'
    });
}



const CustomerService = {
    list, get, suspend, unsuspend
}

export default CustomerService;