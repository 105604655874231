import React, { useState } from 'react';
import { IonItem, IonText, IonLabel, IonIcon, IonNote, IonButtons, IonButton, IonToggle, IonAvatar, IonImg } from '@ionic/react';
import './CustomerListItem.css';
import Moment from 'react-moment';
import 'moment/locale/it';
import { personCircleOutline, callOutline, closeCircleOutline, trashOutline, pencilOutline, personCircleSharp, pencilSharp } from 'ionicons/icons';
// import CurrencyFormat from 'react-currency-format';

interface ItemProps {
    customer: User,
    selectionAction: Function
}


const CustomerListItem: React.FC<ItemProps> = ({ customer, selectionAction }) => {

    return (

        <IonItem /*onClick={() => { selectionAction(customer) }}*/ routerLink={"/customer/"+customer.id} className="booking-item">
            <IonAvatar slot="start">
                <IonImg src={(customer.avatar) ? customer.avatar : '/assets/avatar.svg'} />
            </IonAvatar>
            <IonLabel>
                <h1>{customer.name}</h1>
                <p>{customer.email}</p>
                {(customer.phone && <p>{customer.phone}</p>)}
                {(customer.source && <p>Fonte: {customer.source}</p>)}
            </IonLabel>
        </IonItem>
    );
};

export default CustomerListItem;
