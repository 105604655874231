import React, { useState } from 'react';
import { IonList, IonListHeader, IonHeader, IonToolbar, IonTitle, IonContent, IonItem, IonLabel, IonDatetime, IonButton } from '@ionic/react';
import 'moment/locale/it';
import * as Moment from 'moment';
import { extendMoment, DateRange } from 'moment-range';
// import CurrencyFormat from 'react-currency-format';
const moment = extendMoment(Moment);

interface ItemProps {
    period: DateRange,
    saveAction: Function
}

const ChangeDates: React.FC<ItemProps> = ({ period, saveAction }) => {

    const [newPeriod, setNewPeriod] = useState<DateRange>(period);


    return (

        <IonContent>
            <IonItem>
                <IonLabel>Mostra tutte le date</IonLabel>
            </IonItem>
            <IonItem>
                <IonLabel>Dal</IonLabel>
                <IonDatetime displayFormat="DD/MM/YYYY" placeholder="Scegli data" min="2020-04-25" max={moment(newPeriod.end).format('YYYY-MM-DD')} value={newPeriod.start.toString()} onIonChange={e => { setNewPeriod(moment.range(moment(e.detail.value), newPeriod.end)) }}></IonDatetime>
            </IonItem>
            <IonItem>
                <IonLabel>Al</IonLabel>
                <IonDatetime displayFormat="DD/MM/YYYY" placeholder="Scegli data" min={moment(newPeriod.start).format('YYYY-MM-DD')} max={moment().add(1, 'year').format('YYYY-MM-DD')} value={newPeriod.end.toString()} onIonChange={e => { setNewPeriod(moment.range(newPeriod.start, moment(e.detail.value))) }}></IonDatetime>
            </IonItem>
            <IonButton onClick={() => saveAction(newPeriod)} color="primary" expand="block" className="ion-margin-top">Applica</IonButton>

        </IonContent>
    );
};

export default ChangeDates;
