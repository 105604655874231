import React, { useState, useEffect } from 'react';
import 'moment/locale/it';
import { IonItem, IonNote, IonToggle, IonGrid, IonRow, IonCol, IonLabel, IonDatetime, IonButton, IonIcon, IonList } from '@ionic/react';
import moment from 'moment';
import { saveOutline } from 'ionicons/icons';

interface ItemProps {
    shop: Shop,
    updateAction: Function
}

const OpeningsManager: React.FC<ItemProps> = ({ shop, updateAction }) => {

    const days = () => {
        moment.updateLocale('it', {
            week: {
                doy: 1,
                dow: 1 // Monday is the first day of the week
            }
        });
        return moment.weekdays(true);
    }

    const dayDetail = (day: string, openings: any) => {
        const daynum = days().indexOf(day);
        const result = openings.filter((opening: Opening) => opening.day === daynum);
        return result;
    }

    const [weekDays, setWeekDays] = useState<Array<string>>(days());
    const [newShop, setNewShop] = useState<Shop>(JSON.parse(JSON.stringify(shop)));

   

    const updateDayStatus = (day: string, status: boolean) => {
        let updatedShop = newShop;
        switch (status) {
            case false:
                const obj_to_remove = newShop.openings.map((obj) => { return obj.day }).indexOf(days().indexOf(day));
                updatedShop.openings.splice(obj_to_remove, 1)
                break;

            case true:
                const newDay: Opening = {
                    day: days().indexOf(day),
                    open: "09:00:00",
                    close: "18:00:00"
                }
                updatedShop.openings.push(newDay);
        }
        setNewShop(updatedShop);
        setWeekDays(days());

    }

    const updateDay = (day: string, key: string, value: string) => {

        let updatedShop = newShop;
        const obj_to_edit = newShop.openings.map((obj) => { return obj.day }).indexOf(days().indexOf(day));
        switch (key) {
            case 'open':
                updatedShop.openings[obj_to_edit]['open'] = value;
                break;
            case 'close':
                updatedShop.openings[obj_to_edit]['close'] = value;
                break;
        }
        setNewShop(updatedShop);
    }

    useEffect(() => {
        setNewShop(JSON.parse(JSON.stringify(shop)));
    }, [shop]);

    return (
        <IonList>
            {weekDays.map(weekday => (
                <IonItem key={weekday}>
                    <IonNote slot="end">
                        <IonToggle onIonChange={(e) => { updateDayStatus(weekday, e.detail.checked); }} checked={dayDetail(weekday, newShop.openings).length > 0}></IonToggle>
                    </IonNote>
                    <IonGrid>
                        <IonRow>
                            <h2 className="ion-text-uppercase"><strong>{weekday}</strong></h2>
                        </IonRow>
                        {(dayDetail(weekday, newShop.openings).length > 0) && (
                            <IonRow>
                                <IonCol>
                                    <IonLabel><strong>Apertura</strong></IonLabel>
                                    <IonDatetime onIonChange={(e) => { updateDay(weekday, 'open', String(e.detail.value)) }} value={dayDetail(weekday, newShop.openings)[0].open} displayFormat="HH:mm"></IonDatetime>
                                </IonCol>
                                <IonCol>
                                    <IonLabel><strong>Chiusura</strong></IonLabel>
                                    <IonDatetime onIonChange={(e) => { updateDay(weekday, 'close', String(e.detail.value)) }} value={dayDetail(weekday, newShop.openings)[0].close} displayFormat="HH:mm"></IonDatetime>
                                </IonCol>
                            </IonRow>)}
                    </IonGrid>
                </IonItem>
            ))}
            <IonButton onClick={() => updateAction(newShop)} fill="clear"><IonIcon icon={saveOutline}></IonIcon> Salva</IonButton>
        </IonList>

    );
};

export default OpeningsManager;
