import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonInput, IonItem, IonLabel, IonList, IonItemDivider, IonButton, IonModal, IonText, IonGrid, IonRow, IonCol } from '@ionic/react';
import React, { useState, useContext } from 'react';
import AuthService, { Credentials } from '../../shared/lib/services/AuthService';
import { Plugins } from '@capacitor/core';
import { AppContext } from '../../AppContextProvider';
import { Redirect } from 'react-router';
import { IonReactRouter } from '@ionic/react-router';
import UserService from '../../shared/lib/services/UserService';


const { Modals, Storage } = Plugins;

export const Login: React.FC = () => {


    const [credentials, setCredentials] = useState<Credentials>({});
    const [validForm, setValidForm] = useState<boolean>(false);

    const globalState = useContext(AppContext);
    const { dispatch } = globalState;
    const { state } = globalState;

    //check for token provided as querystring
    const urlParams = new URLSearchParams(window.location.search);
    const access_token = urlParams.get('x');
    const refresh_token = urlParams.get('y');

    if (access_token && refresh_token) {
        UserService.get({ access_token: access_token, refresh_token: refresh_token }).then((data: any) => {
            console.log('ON LOGIN', data);
            if (data) {
                dispatch({ 'type': 'SET_AUTH', 'payload': { tokens: { access_token: access_token, refresh_token: refresh_token }, user: data } });
                storeAuth({ access_token: access_token, refresh_token: refresh_token, user: data });
            }
        });
    }


    const updateCredentials = (credential: 'email' | 'password', value: string | undefined | null) => {
        let cred: Credentials = credentials; cred[credential] = value;
        setCredentials(cred);
        checkFields();
    }
    const checkFields = () => {
        let valid = true;
        if (!credentials?.email || !credentials.password) valid = false;
        setValidForm(valid);
        return valid;
    }
    const submit = () => {
        if (checkFields()) {
            AuthService.login({ email: credentials.email, password: credentials.password }).then((data: any) => {
                console.log('ON LOGIN', data);
                if (data && data.user) {
                    dispatch({ 'type': 'SET_AUTH', 'payload': { tokens: { access_token: data.access_token, refresh_token: data.refresh_token }, user: data.user } });
                    storeAuth(data);
                }
            });
        }
    }

    const storeAuth = async (data: any) => {
        Storage.set({
            key: 'tokens',
            value: JSON.stringify({ access_token: data.access_token, refresh_token: data.refresh_token })
        });
        Storage.set({
            key: 'user',
            value: JSON.stringify(data.user)
        });
        // console.log(data.user);
    }

    const showForgotModal = async () => {
        let promptRet = await Modals.prompt({
            title: 'Recupero password',
            message: 'Inserisci l\'email'
        });
        if (!promptRet.cancelled && promptRet.value) {
            AuthService.resetPassword(promptRet.value);
        }
    }

    return (
        <AppContext.Consumer>
            {state => (state.state.tokens) ?
                <Redirect to='/dashboard' exact={true} /> :
                <IonPage>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>AgendaFacile</IonTitle>
                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <IonGrid>
                            <IonRow className="ion-justify-content-center">
                                <IonCol size="12" sizeLg="6">
                                    <IonList lines="full" className="ion-margin ion-padding">
                                        <IonItem>
                                            <IonLabel position="stacked">E-mail</IonLabel>
                                            <IonInput onIonChange={(e) => updateCredentials('email', e.detail.value)} type="email"></IonInput>
                                        </IonItem>

                                        <IonItem>
                                            <IonLabel position="stacked">Password</IonLabel>
                                            <IonInput onIonChange={(e) => updateCredentials('password', e.detail.value)} type="password"> </IonInput>
                                        </IonItem>
                                        <IonButton disabled={!validForm} onClick={() => { submit(); }} className="ion-margin-top" expand="block">Accedi</IonButton>
                                        <IonButton onClick={() => { showForgotModal(); }} className="ion-margin-top" fill="clear" color="dark" expand="block">Password dimenticata?</IonButton>
                                    </IonList>
                                </IonCol>
                            </IonRow>
                        </IonGrid>
                    </IonContent>
                </IonPage>}
        </AppContext.Consumer>
    );
};