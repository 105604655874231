import request from '../request'

function list(tokens: any) {
    return request({
        url: `/admin/operator`,
        method: 'GET'
    }, tokens);
}

function get(tokens: any, id: string) {
    return request({
        url: `/admin/operator/${id}`,
        method: 'GET'
    });
}

function getHours(tokens: any, id: number, shop_id: number, day: string) {
    return request({
        url: `/admin/operator/${id}/hours`,
        method: 'GET',
        params: { shop_id: shop_id, day: day }
    }, tokens);
}

function create(tokens: any, data: any) {
    return request({
        url: `/admin/operator`,
        method: 'POST',
        data: data
    }, tokens);
}

function update(tokens: any, id: string | number, data: any) {
    return request({
        url: `/admin/operator/${id}`,
        method: 'PUT',
        data: data
    }, tokens);
}

function updateHours(tokens: any, id: string | number, data: any) {
    return request({
        url: `/admin/operator/${id}/hours`,
        method: 'PUT',
        data: data
    }, tokens);
}

function updateStatus(tokens: any, id: string | number, status: boolean) {
    return request({
        url: `/admin/operator/${id}/status`,
        method: 'PUT',
        data: { status: status }
    }, tokens);
}

function destroy(tokens: any, id: string | number) {
    return request({
        url: `/admin/operator/${id}`,
        method: 'DELETE'
    }, tokens);
}


const OperatorService = {
    list, get, create, update, updateHours, updateStatus, destroy, getHours
}

export default OperatorService;