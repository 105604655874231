import React from 'react';
import { IonList } from '@ionic/react';
import 'moment/locale/it';
import CustomerListItem from '../CustomerListItem/CustomerListItem';
// import CurrencyFormat from 'react-currency-format';

interface ItemProps {
    selectionAction: Function,
    customers: User[]
}

const CustomerList: React.FC<ItemProps> = ({ customers, selectionAction }) => {


    return (
        <IonList>
            {customers.map(customer => (
                <CustomerListItem key={customer.id} selectionAction={(customer: Customer) => {selectionAction(customer)}} customer={customer}></CustomerListItem>
            ))}
        </IonList>
    );
};

export default CustomerList;
