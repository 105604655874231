import request from '../request'


function send(tokens: any, title: string, message: string) {
    return request({
        url: `/admin/push`,
        method: 'POST',
        data: { title: title, message: message }
    }, tokens);
}


const SendPushService = {
    send
}

export default SendPushService;