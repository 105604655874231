import React from 'react';
import { IonList } from '@ionic/react';
import 'moment/locale/it';
import OperatorListItem from '../OperatorListItem/OperatorListItem';
// import CurrencyFormat from 'react-currency-format';

interface ItemProps {
    selectionAction: Function,
    operators: Operator[],
    toggleAction: Function
}

const OperatorList: React.FC<ItemProps> = ({ operators, selectionAction, toggleAction }) => {


    return (
        <IonList>
            {operators.map(operator => (
                <OperatorListItem key={operator.id} toggleAction={(operator: Operator) => toggleAction(operator)} selectionAction={(operator: Operator) => {selectionAction(operator)}} operator={operator}></OperatorListItem>
            ))}
        </IonList>
    );
};

export default OperatorList;
