import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonFab, IonFabButton, IonIcon, IonRow, IonCol, IonGrid, IonModal, IonButton, IonInfiniteScroll, IonInfiniteScrollContent, IonActionSheet, IonSearchbar } from '@ionic/react';
import React, { useState, useContext, useEffect } from 'react';
import { callOutline, closeCircleOutline, lockClosed, lockOpen } from 'ionicons/icons';

import './Customers.css';

import { Plugins } from '@capacitor/core';
import { AppContext } from '../../AppContextProvider';
import CustomerService from '../../shared/lib/services/CustomerService';
import CustomerList from '../../components/Customers/CustomerList/CustomerList';
import GenericEmptyState from '../../components/EmptyStates/GenericEmptyState/GenericEmptyState';
const { Modals } = Plugins;


const Customers: React.FC = () => {

  const globalState = useContext(AppContext);
  const { state } = globalState;
  const [currentCustomer, setCurrentCustomer] = useState<Customer>();
  const [customers, setCustomers] = useState<User[]>([]);
  const [rerender, setRerender] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentKey, setCurrentKey] = useState<string>();
  const [showActionSheet, setShowActionSheet] = useState(false);
  const [customersLoaded, setCustomersLoaded] = useState(false);

  const loadCustomers = () => {
    setCurrentPage(1);
    setCustomersLoaded(false);
    setCurrentKey(undefined);
    CustomerService.list(state.tokens, { page: currentPage }).then((response: any) => {
      console.log(response.data);
      setCustomers(response.data);
      setCustomersLoaded(true);
    });
  }

  const infiniteEvent = (event: any) => {
    setCustomersLoaded(false);
    CustomerService.list(state.tokens, { page: currentPage + 1, keyword: currentKey }).then((response: any) => {
      setCurrentPage(currentPage + 1);
      setCustomers(customers.concat(response.data));
      setCustomersLoaded(true);
      event.target.complete();
      // determine if all data is loaded and disable the infinite scroll
      if (response.current_page === response.last_page) {
        event.target.disabled = true;
      }
    });
  }

  const keywordInput = (key: string) => {
    setCustomersLoaded(false);
    let params: any = {
      page: 1
    }
    if (key.length >= 4) {
      setCurrentKey(key);
      params['keyword'] = key;
      CustomerService.list(state.tokens, params).then((response: any) => {
        setCustomers(response.data);
        setCustomersLoaded(true);
      });
    }
  }

  const switchStatus = (customer: Customer | undefined) => {
    alert(customer?.active_for_app)
  }

  useEffect(() => {
    loadCustomers();
  }, []);

  useEffect(() => {
  }, [rerender]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Clienti</IonTitle>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar onIonClear={() => loadCustomers()} showCancelButton="focus" onIonInput={(event: any) => { keywordInput(event.target.value); }} placeholder="Cerca per nome, email o telefono...">
          </IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Clienti</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol size="12">
              {(customersLoaded && customers.length < 1 ?
                <GenericEmptyState custom_text="Nessun cliente." item_type="clienti"></GenericEmptyState>
                : <CustomerList selectionAction={(customer: any) => { console.log('from selection: ', customer); setCurrentCustomer(customer); setShowActionSheet(true); }} customers={customers} />
              )
              }
              <IonInfiniteScroll threshold="100px" onIonInfinite={(e) => infiniteEvent(e)}>
                <IonInfiniteScrollContent loadingSpinner="circular" loadingText="Carico altri...">
                </IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonActionSheet
          isOpen={showActionSheet}
          onDidDismiss={() => setShowActionSheet(false)}
          buttons={[{
            text: 'Chiama cliente',
            icon: callOutline,
            handler: () => {
              if (currentCustomer?.phone) { window.open('tel:' + currentCustomer?.phone, '_blank'); } else {
                Modals.alert({
                  title: "Non disponibile",
                  message: 'L\'utente non ha ancora inserito un numero vaido'
                });
              }
            }
          }]}
        >
        </IonActionSheet>
      </IonContent>
    </IonPage >
  );
};

export default Customers;
