import React, { useState, useEffect } from 'react';
import { IonRange, IonLabel, IonText, IonRow, IonGrid, IonCol } from '@ionic/react';
import moment from 'moment';
import Moment from 'react-moment';

interface ItemProps {
    start?: string,
    end?: string,
    min?: string,
    max?: string,
    changeHandler?: Function,
    showLabel?: boolean
}

const IonTimeRange: React.FC<ItemProps> = ({ min, max, start, end, changeHandler, showLabel }) => {

    const [currentStart, setCurrentStart] = useState<string>();
    const [currentEnd, setCurrentEnd] = useState<string>();

    const startTime = (start) ? +moment('1993-06-01 ' + start ).toDate() : +moment('1993-06-01 09:00:00').toDate();
    const endTime = (end) ? +moment('1993-06-01 ' + end ).toDate() : +moment('1993-06-01 18:00:00').toDate();
    const minTime = (min) ? +moment('1993-06-01 ' + min ).toDate() : +moment('1993-06-01 00:00:00').toDate();
    const maxTime = (max) ? +moment('1993-06-01 ' + max ).toDate() : +moment('1993-06-01 23:59:00').toDate();

    console.log('start: ',start);

    const [rangeValue, setRangeValue] = useState<{
        lower: number;
        upper: number;
    }>({ lower: startTime, upper: endTime });


    useEffect(() => {
        setCurrentStart(moment(new Date(rangeValue['lower'] as number)).format('HH:mm:ss'));
        setCurrentEnd(moment(new Date(rangeValue['upper'] as number)).format('HH:mm:ss'));
        if (changeHandler) changeHandler({ start: currentStart, finish: currentEnd });

    }, [rangeValue]);

    return (
        <IonGrid>
            {(showLabel) && <IonRow>
                <IonCol className="ion-text-center">
                    <IonLabel>Dalle <strong><Moment parse="HH:mm:ss" format="HH:mm">{currentStart}</Moment></strong> alle <strong><Moment parse="HH:mm:ss" format="HH:mm">{currentEnd}</Moment></strong></IonLabel>
                </IonCol>
            </IonRow>}
            <IonRow>
                <IonCol>
                    <IonRange pin={false} snaps={true} ticks={true} step={300000} min={minTime} max={maxTime} dualKnobs={true} value={rangeValue} onIonChange={(e: any) => { setRangeValue(e.detail.value); }} />
                </IonCol>
            </IonRow>
        </IonGrid>
    );
};

export default IonTimeRange;
