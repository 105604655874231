import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonFab, IonFabButton, IonIcon, IonList, IonModal, IonButton, IonRow, IonCol, IonGrid } from '@ionic/react';
import React, { useState, useEffect, useContext } from 'react';
import { add } from 'ionicons/icons';

import './Services.css';

import { Plugins } from '@capacitor/core';
import ServiceList from '../../components/Services/ServiceList/ServiceList';
import NewBooking from '../../components/Bookings/NewBooking/NewBooking';
import ServiceEditor from '../../components/Services/ServiceEditor/ServiceEditor';
import ServiceService from '../../shared/lib/services/ServiceService';
import { AppContext } from '../../AppContextProvider';
const { Modals } = Plugins;


const Services: React.FC = () => {

  const globalState = useContext(AppContext);
  const { dispatch } = globalState;
  const { state } = globalState;


  const [showNewModal, setShowNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentService, setCurrentService] = useState<Service>();
  const [services, setServices] = useState<Service[]>([]);


  const loadServices = () => {
    ServiceService.list(state.tokens).then((response: any) => {
      setServices(response.data);
    });
  }

  const saveNewService = (service: Service) => {
    ServiceService.create(state.tokens, service).then((response) => {
      console.log(response);
      setShowNewModal(false);
      loadServices();
    }).catch((err) => {
      alert('Impossibile completare la richiesta!');
    })
  }

  const updateService = (service: Service) => {
    ServiceService.update(state.tokens, service.id, service).then((response) => {
      setShowEditModal(false);
      setCurrentService(undefined);
    }).catch((error) => {
      alert('Impossibile aggiornare!');
    });
  }

  const showDeleteConfirm = async (service: Service) => {
    let confirmRet = await Modals.confirm({
      title: 'Annulla',
      message: 'Sei sicuro di voler eliminare il servizio?'
    });
    if (confirmRet.value == true) {
      ServiceService.destroy(state.tokens, service.id).then((response) => {
        loadServices();
      });
    }
  }


  useEffect(() => {
    loadServices();
  }, []);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Servizi</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonFab vertical="top" horizontal="end">
          <IonFabButton onClick={() => { setShowNewModal(true); }}>
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Servizi</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol>
              <ServiceList deleteAction={(service: Service) => { showDeleteConfirm(service) }} selectionAction={(service: Service) => { setCurrentService(service); setShowEditModal(true); }} services={services} />
            </IonCol>
          </IonRow>
        </IonGrid>

        <IonModal cssClass="new-modal" swipeToClose={true} onDidDismiss={() => { setShowNewModal(false); }} isOpen={showNewModal}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => { setShowNewModal(false); }}>Annulla</IonButton>
              </IonButtons>
              <IonTitle>Nuovo servizio</IonTitle>
            </IonToolbar>
          </IonHeader>
          <ServiceEditor saveAction={(service: Service) => { saveNewService(service); }} />
        </IonModal>

        <IonModal cssClass="new-modal" swipeToClose={true} onDidDismiss={() => { setShowEditModal(false); }} isOpen={showEditModal}>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="end">
                <IonButton onClick={() => { setShowEditModal(false); }}>Annulla</IonButton>
              </IonButtons>
              <IonTitle>Modifica servizio</IonTitle>
            </IonToolbar>
          </IonHeader>
          <ServiceEditor service={currentService} saveAction={(service: Service) => { updateService(service); }} />
        </IonModal>
      </IonContent>
    </IonPage >
  );
};

export default Services;
