import Menu from './components/Menu';
import React, { useEffect, useState } from 'react';
import { IonApp, IonRouterOutlet, IonSplitPane, IonAlert } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Dashboard from './pages/Dashboard/Dashboard';
import Calendar from './pages/Calendar/Calendar';
import Shops from './pages/Shops/Shops';
import Services from './pages/Services/Services';
import Team from './pages/Team/Team';
import { AppContextProvider, AppContext } from './AppContextProvider';
import { Login } from './pages/Login/Login';
import Openings from './pages/Openings/Openings';
import Customers from './pages/Customers/Customers';
import Customer from './pages/Customer/Customer';
import OperatorHours from './pages/OperatorHours/OperatorHours';
import Push from './pages/Push/Push';



const App: React.FC = () => {

  return (
    <IonApp>
      <AppContextProvider>
        <IonReactRouter>
          <Route path="/login" component={Login} />
          <AppContext.Consumer>
            {state => (state.state.tokens) ?
              <IonSplitPane contentId="main">
                <Menu />
                <IonRouterOutlet id="main">
                  <Route path="/dashboard" component={Dashboard} exact />
                  <Route path="/calendar" component={Calendar} exact />
                  <Route path="/shops" component={Shops} exact />
                  <Route path="/openings" component={Openings} exact />
                  <Route path="/services" component={Services} exact />
                  <Route path="/team" component={Team} exact />
                  <Route path="/team/:id/hours" component={OperatorHours} exact />
                  <Route path="/customers" component={Customers} exact />
                  <Route path="/push" component={Push} exact />
                  <Route path="/customer/:id" component={Customer} />
                  <Redirect from="/" to="/dashboard" exact />
                </IonRouterOutlet>
              </IonSplitPane> :
              <>
                <Redirect from="*" to={"/login" + window.location.search} />
              </>
            }
          </AppContext.Consumer>
        </IonReactRouter>
      </AppContextProvider>
    </IonApp>
  );
};

export default App;
